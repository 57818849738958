<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/company' }">公司管理</el-breadcrumb-item>
          <el-breadcrumb-item>公司详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="公司名称" prop="name">{{form.name}}</el-form-item>
          <el-form-item label="公司地址" prop="addr">{{form.addr}}</el-form-item>
          <el-form-item label="菜单配置">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item title="公司菜单配置" name="1">
                <el-tree
                  ref="menuRefs"
                  :data="dataTree"
                  node-key="id"
                  default-expand-all
                  :props="defaultProps">
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
          
          <el-form-item label="">
            <el-button size="small" @click="$router.push('/company')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      row: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        addr:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      loading: false,
      activeNames: ['1'],
      dataTree: [],
      defaultProps: {
        children: 'childTree',
        label: 'title',
      }
    }
  },
  methods: {
    handleChange(val) {},
    loadDetail() {
      this.$ajax.post('companyDetail', {
        id: this.row.id
      }).then(res => {
        this.form = res.data
        this.dataTree = res.data.menuTrees
      })
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push(item.id)
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/company')
      return
    }
    this.row = this.$route.params.row
    this.loadDetail()
  }
}
</script>
<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.el-input { width:50%; }
.el-collapse {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow: hidden;
  width: 50%;
}
.el-collapse-item {
  box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.5);
  border: 0px solid #eee;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
/deep/.el-collapse-item__header {
  height: 42px;
  padding: 0 10px;
  border-bottom: 0;
}
/deep/.el-tree-node__label {
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 2px;
}
/deep/.el-tree-node__content {
  height: 35px;
}
</style>
<style>

</style>